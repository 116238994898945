import React, { useEffect, useState } from "react";
import './Home.css'
import './smallscreen.css'
import Services from "./components/Services"
import Experiences from './components/Experiences'
import SingleRow from './components/SingleRow'
import Pics from './images/Pics.jpg';
import { Link } from "react-router-dom";


function Home() {

  const [projects, setProjects] = useState([]);

  useEffect( () =>{
    async function fetchData() {
      const data = await fetch("/assets/projects.json").then(data => data.json());
    setProjects(data);
    }
    fetchData();
    
  }, []);

  return (
    <div className="Home" >

          <div className='page-wrapper'>

              <div className='Body'>

                <div className="BodyLeft">

                    <div><h4> Consantrating on Computer Science. Developing enterprise backend applications.
                    </h4>
                    </div>

                    <div className='LastUpdatedDiv' >
                    <h4 className='LastUpdated' style={{fontSize:24, fontWeight:400}}> <div id="fragment1">Backend Developer</div>
                    <div id="fragment2">Computer Scientist</div></h4>

                    </div>

                </div>

                <div className="BodyRight" >

                  <p>Hi, I am Furkan.<span className="Designer">Backend Developer</span> and <span className="Developer"> Computer Scientist </span> based in Istanbul.</p>
                
                </div>

              </div>
              

                  <div className='Projects' >
                    <div className='project_collage' style={{display:'flex', gap:12}}>{
                      projects.map(project => (
                        <SingleRow key={project.id} project={project}/>
                      ))
                    }
                    </div>


                  <Link to='/Projects'><button className='allwork'>All Works</button></Link>

              </div>

              
              <div className='Info'>

                  <div className='services'>
                      <h4 className='ServicesTitle'>Hard Skills</h4>
                      <Services title='Backend Technologies'/>
                      <Services title='On-premises and Cloud-based servers'/>
                      <Services title='Java'/>
                      <Services title='SQL'/>
                      <Services title='Spring Boot'/>
                      <Services title='Hibernate'/>
                      <Services title='Git'/>
                  </div>
                  
                  <div className='Summary'>
                        <div className='Summary'>
                        <h3 className='SumTitle'>Currently working at <a href='https://www.solidict.com/'><button className='solidict'><span className='SolidBox'>Solid-ICT</span></button></a>. Developing enterprise backend applications. </h3>
                        </div>
                        
                        <div>
                            <div className='SummaryBody'>
                              <p className='sump'>  I have high understanding on web development technogly concepts. 
                              I am willing to learn whatever is needed for the task.
                              </p>
                            </div>
                            <Link to='/About'><button className='ReadMore'>Read More</button></Link>
                        </div>
          
                  </div>
              
              </div>

              </div>

              <div className='BigPic'>
                <img className='picbig' src={Pics} alt=""/>
              </div>

              <div className='Experience' style={{paddingTop:144, display:'flex', justifyContent:'space-between',
              backgroundColor:'#f0f0f0', paddingLeft:40, paddingRight:40}}>
                
                <div className='exp-left'>
                  <h3 className='exp-title'>Experiences</h3>
                </div>
                
                <div className='ExpRight' style={{paddingBottom:84}}>
                  <Experiences name='Solid-ICT' year='2022' 
                  define='Developing enterprise backend applications with Java'/>
                   <Experiences name='HdPiano' year='2023'
                  define='Giving tech consultancy as an independent contractor'/>
                </div>


           </div>

    </div>
  );
}

export default Home;
