import React from "react";
import "./About.css";
import './Home.css';
import './App.css'
import Services from "./components/Services";
import Experiences from "./components/Experiences";
import AboutPic from './images/Frame 4.png'
import AboutPicMobile from './images/mobil.png';




function About() {

  return (
    <div className="About" >

          <div className='PageWrapperAbout' style={{ paddingTop:160, paddingLeft:40, paddingRight:40, paddingBottom:120 , backgroundColor:'#f0f0f0'}}>

            <div className='TitleDiv'>
              <p className='Title'>
                I am Furkan. <span className="Developer">Backend Developer</span> and <span className="Studio">Computer Scientist</span> based in Istanbul. Currently working at Solid-ICT.
              </p>

            </div>

            

            <div className='Info'>

                <div className='services'>
                    <div style={{paddingBottom:100}}>
                      <h4 className='ServicesTitle'>Hard Skills</h4>
                      <Services title='Backend Technologies'/>
                      <Services title='On-premises and Cloud-based servers'/>
                      <Services title='Java'/>
                      <Services title='SQL'/>
                      <Services title='Spring Boot'/>
                      <Services title='Hibernate'/>
                      <Services title='Git'/>
                    </div>

                    <div style={{paddingBottom:100}}>
                      <h4 className='ServicesTitle'>Languages</h4>
                      <Services title='Turkish - Native'/>
                      <Services title='English - Advanced'/>

                    </div>


                </div>

                <div className='Summary'>
                      <div className='Summary' style={{paddingBottom:60}}>
                      <h3 className='SumTitle'>Passion for Computer Science:</h3>
                      <span className='SolidBox'>Web Development</span><span className='SolidBox'>Computer Science</span>
                      </div>
                  
                      <div>
                          <div className='SummaryBody' style={{maxWidth:500}}>
                            <p className='sump'>  My name is Furkan. I hold a Bachelor's degree in Computer Engineering and have been working as a backend developer since 2022. 
                            During this time, I have honed my skills in backend development, servers and data science. 
                            I also have knowledge of fundamental concepts of the front-end development. This portfolio website is coded by me with react.js
                            </p>

                            <p className='sump'>
                            In my spare time, I enjoy exploring new web technologies, travelling the world and boxing.
                            Please feel free to get in touch if you have any questions or if you'd like to work together.
                            </p>
                          </div>
                      </div>
                </div>
                </div>



                
                <div className='Experience2' style={{paddingTop:144, display:'flex', justifyContent:'space-between',
              backgroundColor:'#f0f0f0'}}>
                
                <div className='exp-left'>
                  <h3 className='exp-title'>Education</h3>
                </div>
                
                <div className='ExpRight' style={{paddingBottom:84}}>
                  <Experiences name='Meta Front-End Professional Certificate' 
                  />
                  <Experiences name='Computer Engineering' 
                  define='Yildiz Technical University, Bachelors Degree'/>
                </div>

              </div>

                <div className='Experience2' >
                
                <div className='ExpLeft'>
                  <h3 className='exp-title'>Experiences</h3>
                </div>
                
                <div className='ExpRight'>
                <Experiences name='Solid-ICT' year='2022' 
                  define='Developing enterprise backend applications with Java'/>
                   <Experiences name='HdPiano' year='2023'
                  define='Giving tech consultancy as an independent contractor'/>
                </div>

              </div>
           </div>

          

    </div>
  );
}

export default About;
